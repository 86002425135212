<template>
  <transition name="fade">
    <div class="modal">
      <div class="container h-full flex flex-col items-center justify-center">
        <div class="content">
          <p class="title">Vai desistir? Nos conte o motivo...</p>
          <div>
            <div class="row min-height">
              <div v-for="motivo of motivos" :key="motivo.id" class="my-2 text-sm lg:my-0">
                <v-radio class="my-1" name="motivoDesistenciaId" v-model="giveUpForm.motivoDesistenciaId" :label="motivo.descricao" :value="motivo.id" v-validate="'required'"></v-radio>
              </div>
              <p class="text-xs text-red-600">{{errors.first("motivoDesistenciaId")}}</p>
            </div>
            <div class="row">
              <p class="form_title">Nos conte mais sobre sua experiência, dúvidas ou reclamações sobre a Tutu Digital</p>
              <v-input type="textarea" class="w-full" name="observacoes" v-model="giveUpForm.observacoes" label=""></v-input>
            </div>
            <div class="row">
              <div class="checkbox">
                <v-checkbox class="text-sm" name="aceitoReceberNovasPropostasNoFuturo" v-model="giveUpForm.aceitoReceberNovasPropostasNoFuturo" label="Aceito receber novas propostas no futuro"/>
              </div>
            </div>
            <div class="flex flex-col lg:flex-row justify-center items-center">
              <v-button color="error" class="lg:my-2 lg:mr-4" size="lg" :loading="loading" @click="submit">Quero desistir</v-button>
              <v-button class="lg:my-2" size="lg" :loading="loading" @click="$emit('closeModal')">Quero continuar</v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VRadio from '@/components/Inputs/Radio'

import Simulation from '@/services/Landing/Simulation'

export default {
  components: { VButton, VInput, VCheckbox, VRadio },
  props: ['giveUpForm'],
  data  () {
    return {
      motivos: [],

      loading: false,
      error: null
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          this.$emit('giveUp')
        }
      }
    }
  },
  async mounted () {
    try {
      this.motivos = (await Simulation.getGiveUpReasons()).data.data
    } catch (err) {
      console.error(err)
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed inset-0 mx-auto my-auto z-50
  background: rgba(29,26,26,.50)

.content
  @apply w-full relative px-4 pt-4 h-152 bg-white rounded-lg shadow-lg
  @screen lg
    @apply px-10

.title
  @apply font-bold text-xl text-primary mt-4 mb-4
  @screen lg
    @apply text-2xl

.form_title
  @apply font-bold text-base text-black mt-6 opacity-75
  @screen lg
    @apply text-lg

.row
  @apply block flex flex-col

.min-height
  min-height: 10rem

.subtitle
  @apply text-sm opacity-75

.label
  @apply text-sm opacity-75 cursor-pointer mr-2

.radio
  @apply mr-1 my-auto

.text
  @apply text-sm opacity-75 mb-2

</style>
