<template>
  <div class="text-center">
    <p class="text-primary text-3xl lg:text-5xl mb-4">
      <i class="fas fa-check icon"></i>
    </p>
    <p class="title">Recebemos sua solicitação de empréstimo!</p>
    <p class="subtitle">Em breve você receberá um e-mail com a resposta do seu pedido!</p>
    <p class="thanks">Obrigado!</p>
    <v-text-button class="flex justify-center opacity-75 mt-16 lg:mt-40" @click="$emit('prevStep')">Voltar para a página principal</v-text-button>
  </div>
</template>

<script>
import VTextButton from '@/components/Buttons/TextButton'

export default {
  components: { VTextButton },
  data () {
    return {
    }
  },
  methods: {
    mounted() {
          history.pushState({}, null, '/timeout');

      ga('send', 'event', 'timeout', 'timeout', 'timeout');
      dataLayer.push({'event': 'timeout'});
      ga('set', 'page', 'timeout');
      }
  }

}
</script>

<style lang="sass" scoped>

.icon
  @apply rounded-full border-2 border-primary p-2

.title
  @apply mb-4 text-primary text-xl font-bold
  @screen lg
    @apply text-3xl

.subtitle
  @apply mb-4 uppercase text-secondary opacity-75
  @screen lg
    @apply text-xl

.thanks
  @apply text-primary font-bold text-xl

</style>
