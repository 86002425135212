<template>
  <div>
    <give-up-modal v-show="giveUpModal" @closeModal="giveUpModal = false; $emit('resetGiveUp')" @giveUp="$emit('giveUp')" :giveUpForm="giveUpForm"></give-up-modal>
    <p class="title">UHUL, que bom ter você por aqui!</p>
    <p class="subtitle">Olha que legal, sua empresa já tem um limite
      pré-aprovado e está a poucos passos de ter acesso ao crédito <span class="text-secondary">fácil, simples e justo.</span></p>
    <div class="content">
      <div class="content_box">
        <div class="content_box_title">
          {{preApproved.valor | money}}
        </div>
        <div class="content_box_subtitle">
          Valor pré-aprovado
        </div>
      </div>
      <div class="content_box">
        <div class="content_box_title">
          {{String(preApproved.taxaMensal).replace('.', ',')}}%
        </div>
        <div class="content_box_subtitle">
          Taxa ao mês
        </div>
      </div>
      <div class="content_box">
        <div class="content_box_title">
          {{preApproved.prazo}} meses
        </div>
        <div class="content_box_subtitle">
          Prazo
        </div>
      </div>
      <div class="content_box">
        <div class="content_box_title">
          {{preApproved.valorParcela | money}}
        </div>
        <div class="content_box_subtitle">
          Valor da parcela*
        </div>
      </div>
    </div>
    <div v-if="!preApproved.senhaCriada">
      <p class="title">Para continuar...</p>
      <p class="subtitle">Crie a senha para acessar a plataforma e dar continuidade ao processo.</p>
      <div class="content">
        <div class="content_password">
          <v-input name="senha" type="password" v-model="password.senha" :error="errors.first('senha')" label="Senha" v-validate="'required|min:6'"></v-input>
          <v-input name="confirmacaoSenha" type="password" v-model="password.confirmacaoSenha" :error="errors.first('confirmacaoSenha')" label="Repetir senha" v-validate="{required: true, min: 6, is: password.senha}"></v-input>
        </div>
        <div class="content_checkboxes">
          <v-checkbox class="text-sm" name="termos" :error="errors.first('termos')" v-model="termos" v-validate="'required:true'">
            <span class="cursor-pointer opacity-100"> Declaro que li e estou de acordo com os
            <span class="text-secondary">Termos e Condições para Tomadores e Investidores</span></span>
          </v-checkbox>
          <v-checkbox class="text-sm my-2" name="termosPrivacidade" :error="errors.first('termosPrivacidade')" v-model="termosPrivacidade" v-validate="'required:true'">
            <span class=""> Declaro que li e estou de acordo com os
            <span class="text-secondary">Termos e Condições de Uso e Política de Privacidade</span></span>
          </v-checkbox>
        </div>
      </div>
      <div class="buttons">
        <v-button class="flex justify-center" @click="submit">Criar senha</v-button>
        <v-text-button class="w-full h-10 my-4 flex justify-end" @click="giveUpModal = true">Desistir desse empréstimo pré-aprovado</v-text-button>
      </div>
    </div>
    <div v-else>
      <div class="buttons">
        <v-button class="flex justify-center" @click="$router.push('/tomador/painel')">Ir para o Painel</v-button>
        <v-text-button class="w-full h-10 my-4 flex justify-end" @click="giveUpModal = true">Desistir desse empréstimo pré-aprovado</v-text-button>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VButton from '@/components/Buttons/Button'
import VTextButton from '@/components/Buttons/TextButton'
import GiveUpModal from '@/components/Modal/GiveUpModal'

import Simulation from '@/services/Landing/Simulation'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  // adicionei o user_id aqui, verificar
  props: ['password', 'preApproved', 'giveUpForm', 'userId'],
  components: { VInput, VButton, VTextButton, GiveUpModal, VCheckbox },
  mixins: [MoneyFilter],
  data () {
    return {
      termos: false,
      termosPrivacidade: false,

      loading: false,
      giveUpModal: false
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        console.log(this.password);
        console.log(this.preApproved);
        this.password.codigoProposta = this.preApproved.codigo;
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const response = (await Simulation.createPassword(this.password)).data.data
            this.$store.dispatch('setLogin', response)
            this.$router.push('/tomador/painel')
            this.$store.dispatch('notification', { type: 'success', message: 'Senha criada com sucesso! Confirme sua conta pelo e-mail!' })
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              this.errors.add({ field: 'senha', msg: error.response.data.erros.password.split(':')[1].slice(0, -2) })
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    },
    mounted() {
      console.log(this.password);
      console.log(this.preApproved);
      console.log(this.userId);
        history.pushState({}, null, '/credito-aprovado');

      ga('send', 'event', 'credito-aprovado', 'credito-aprovado', 'credito-aprovado');
      dataLayer.push({'event': 'credito-aprovado'});
      ga('set', 'page', 'credito-aprovado');
    }
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply w-full mb-2 text-primary text-xl font-bold text-center
  @screen lg
    @apply text-3xl text-left

.subtitle
  @apply w-full mb-4 text-gray-700 text-base
  @screen lg
    @apply text-2xl

.content
  @apply flex flex-col mb-4
  @screen lg
    @apply flex-row

.content_box
  @apply border-l-4 border-secondary pl-5 mr-8 mb-3 flex flex-col justify-center
  @screen lg
    @apply w-48

.content_box_title
  @apply text-primary text-xl font-bold
  @screen lg
    @apply text-2xl

.content_box_subtitle
  @apply text-xs text-gray-700
  @screen lg
    @apply text-sm

.content_password
  @apply mb-3
  @screen lg
    @apply w-1/2 mb-0 mr-6

.content_checkboxes
  @apply flex flex-col justify-around

.input
  @apply border-black border-b mb-4 pb-1 text-sm w-full
  @screen lg
    @apply w-11/12
  &:focus
    @apply outline-none border-primary

.input_show_password
  @apply absolute text-gray-700 mt-2 right-0 mr-2
  @screen lg
    @apply mr-12

.buttons
  @apply w-full flex flex-col my-4 items-center
  @screen lg
    @apply items-start flex-row justify-between my-6

</style>
