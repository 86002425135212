<template>
  <div class="wrapper">
    <div class="main">
      <div class="content">
        <p class="content_title">De quanto você precisa?</p>
        <vue-slider ref="slider" silent adsorb :process="false" tooltip="always" railStyle="height: 2px"
          :dotOptions="dotOptions" :tooltipFormatter="v => formatMoney(v)" :data="possibleValues" :marks="marks"
          v-model="businessData.valorEmprestimoDesejado">
          <template v-slot:label="{ label }">
            <div class="vue-slider-label" :class="label === 10000 && 'vue-slider-label-left'">{{ label | money }}</div>
          </template>
        </vue-slider>
        <p class="content_title">Em quantas parcelas?</p>
        <div class="instalments">
          <div v-for="inst of allInstalments" :key="inst" class="instalment" @click="businessData.prazoDesejado = inst"
            :class="businessData.prazoDesejado === inst? 'instalment_active' : ''">{{inst}}x
          </div>
        </div>
      </div>
      <div class="content">
        <div class="values">
          <p class="values_title">A melhor opção:</p>
          <p class="values_title">{{`${businessData.prazoDesejado} x ${formatMoney(instalment)}`}}</p>
          <p class="values_hint">* O valor das parcelas pode variar de acordo com a análise de crédito do cliente.</p>
        </div>
      </div>
      <div class="buttons">
        <v-button @click="submit" :loading="loading" :disabled="loading">Avançar</v-button>
      </div>
    </div>
  </div>
</template>
        
<script>
import VueSlider from 'vue-slider-component'
import VButton from '@/components/Buttons/Button'
import MoneyFilter from '@/mixins/moneyFilter'
import MathTutu from '@/assets/js/math.tutu.core.js'
import 'vue-slider-component/theme/antd.css'

export default {
  props: ['businessData', 'microcredit', 'loading'],
  components: { VButton, VueSlider },
  mixins: [MoneyFilter],
  data() {
    return {
      possibleValues: [],
      marks: [],
      loading: false,
      allInstalments: [6, 12, 18, 24],

      dotOptions: {
        disable: true,
        style: 'bg-black'
      }
    }
  },
  computed: {
    instalment() {
      return MathTutu.pmtRound(this.businessData.valorEmprestimoDesejado, 2.99, this.businessData.prazoDesejado, 0)
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          this.$emit('nextStep')
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
        this.loading = false
      }
    },
    loadData() {
      this.possibleValues = [10000]
      this.marks = [10000]
      if (this.microcredit) {
        this.marks.push(15000)
        for (let i = 3; i <= 15000 / 500; i++) {
          this.possibleValues.push(i * 500)
        }
      } else {
        this.marks.push(200000)
        for (let i = 1; i <= 200000 / 5000; i++) {
          this.possibleValues.push(i * 5000)
        }
      }
      this.$refs.slider.setValue(10000)
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    microcredit() {
      this.loadData()
    }
  }
}
</script>
        
<style lang="sass" scoped>

.wrapper
  @apply flex justify-center

.main
  @apply flex flex-col items-center
  @screen lg
    @apply flex-row flex-wrap items-start

.content
  @apply mt-4 mb-6
  @screen lg
    @apply w-1/2

.content_title
  @apply text-primary text-xl font-bold text-center
  @screen lg
    @apply text-2xl text-left
  &:last-of-type
    @apply mt-16

.input
  @apply border-black border-b mb-4 mt-2 text-xl w-full
  @screen lg
    @apply w-11/12 text-2xl
  &:focus
    @apply outline-none border-primary
    transition: all 250ms

.instalments
  @apply flex mt-4

.instalment
  @apply rounded-full w-12 h-8 mx-2 bg-gray-400 text-gray-700 cursor-pointer flex justify-center items-center select-none
  @screen lg
    @apply text-lg w-16 h-8 mt-2
  &_active
    @apply bg-secondary text-gray-100
  &:hover
    @apply border-2 border-secondary

.buttons
  @apply mb-4
  @screen lg
    @apply w-full my-8

.values
  @apply rounded-lg bg-secondary text-white text-center mx-auto py-4 px-2 w-full
  @screen lg
    @apply w-3/4
  &_title
    @apply font-bold text-xl
    @screen lg
      @apply text-3xl
  &_hint
    @apply text-xs opacity-50 mt-2
    @screen lg
      @apply text-sm

</style>

<style lang="sass">

.vue-slider
  @apply mt-16 mb-6 mx-6
  @screen lg
    @apply ml-0 mr-10
  &:hover
    .vue-slider-dot-handle:hover
      @apply border-primary
  &-label
    @apply absolute text-xss mt-4 opacity-25 right-0
    @screen lg
      @apply text-xs
    &-left
      @apply right-auto

.vue-slider .vue-slider-mark
  height: 0 !important
  width: 0 !important

.vue-slider-dot
  height: 1.5rem !important
  width: 1.5rem !important
  &-handle
    @apply bg-primary border-primary
    &:hover
      @apply bg-primary
  &-tooltip-inner
    @apply bg-secondary border-secondary

</style>
