<template>
  <div class="modal">
    <div class="backdrop" @click="close" />
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="close">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <slot name="body">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-basic',
  methods: {
    close() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass" scoped>
  .modal
    @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

  .backdrop
    @apply fixed z-40 w-full h-full top-0 left-0
    background: rgba(29,26,26,.50)

  .content
    @apply relative h-auto	 w-full bg-white z-50 flex flex-col pb-6 pt-8 rounded-lg
    @screen lg
      @apply w-11/12 h-auto	

  .close
    @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
    @screen lg
      @apply m-2 p-2 text-lg

  .content_modal
    @apply flex flex-col w-full px-4 overflow-auto
    @screen lg
      @apply px-8

  .row
    @apply w-full flex justify-center
    @screen md
      @apply justify-center mt-6

  .title
    @apply text-xl text-primary font-bold mb-3

  ul li
    @apply leading-normal mb-2

  ::-webkit-scrollbar
    @screen md
      width: 8px
    @screen lg
      width: 8px

  ::-webkit-scrollbar-track
    @screen md
      @apply bg-gray-400 rounded-lg
    @screen lg
      @apply bg-gray-400 rounded-lg

  ::-webkit-scrollbar-thumb
    @screen md
      @apply bg-gray-600 rounded-lg
      &:active
        @apply bg-secondary
    @screen lg
      @apply bg-gray-600 rounded-lg
      &:active
        @apply bg-secondary

  </style>
