<template>
  <div class="wrapper">
    <transition name="fade">
      <contact-authorizations-modal v-show="modalContact" :contact="businessData.autorizacoesContato"
        @closeModal="modalContact = false"></contact-authorizations-modal>
    </transition>
    <transition name="fade">
      <terms-politics-modal v-show="modalTermsPolitics" @closeModal="modalTermsPolitics = false" />
    </transition>
    <transition name="fade">
      <terms-business-investor-modal v-show="modalTermsBusinessInvestor"
        @closeModal="modalTermsBusinessInvestor = false" />
    </transition>
    <div class="main">
      <p class="title">Preciso te conhecer!</p>
      <div class="content lg:pr-2">
        <v-input class="w-full" name="cnpj" v-model="businessData.cnpj" :error="errors.first('cnpj')" label="CNPJ*"
          v-validate="'required|cnpj'" mask="##.###.###/####-##"></v-input>
        <v-input class="w-full" name="telefoneContatoDireto" v-model="businessData.telefoneContatoDireto"
          :error="errors.first('telefoneContatoDireto')" label="Telefone*" v-validate="'required|min:14|max:15'"
          :mask="['(##) ####-####', '(##) #####-####']" :masked="true"></v-input>
        <v-input class="w-full" name="motivoSolicitacaoId" v-model="businessData.motivoSolicitacaoId"
          label="Qual a finalidade do crédito solicitado?*" :options="reasons" itemValue="id" itemText="descricao"
          v-validate="{ required: true, is_not: -1 }" :error="errors.first('motivoSolicitacaoId')"></v-input>
      </div>

      <div class="content lg:pl-2">
        <v-input class="w-full" v-uppercase v-trim name="nomeEmpresa" v-model="businessData.nomeEmpresa"
          :error="errors.first('nomeEmpresa')" label="Qual o nome da sua empresa?*" v-validate="'required|min:2'">
        </v-input>
        <v-input class="w-full" v-lowercase v-trim name="emailContato" v-model="businessData.emailContato"
          :error="errors.first('emailContato') || errors.first('email')" label="Seu melhor e-mail*"
          v-validate="'required|email'"></v-input>
        <v-input class="w-full" name="faturamentoMedioMensal" v-model.number="businessData.faturamentoMedioMensal"
          :error="errors.first('faturamentoMedioMensal')" label="Qual seu faturamento mensal?"
          v-validate="'decimal:2|min_value:0'" type="money"></v-input>
      </div>


      <div class="row content lg:pl-2">
        <p class="radio_label mb-2">Qual o melhor tipo de operação para sua empresa?</p>
        <v-checkbox class="text-sm" v-model="dup" name="dup" label="Antecipação de Duplicatas" :info="true"
          @onInfoClick="showDescDup" />
        <v-checkbox class="text-sm" v-model="cc" name="cc" label="Antecipação de Recebíveis Cartão de Crédito"
          :info="true" @onInfoClick="showDescCc" />
        <v-checkbox class="text-sm" v-model="cg" name="cg" label="Capital de giro" :info="true"
          @onInfoClick="showDescCg" />

        <p class="error1" v-show="operationTypeSelected">Selecione pelo menos um tipo de operação</p>
      </div>

      <div class="row content lg:pl-2">
        <p class="radio_label mb-2">Empresa ou sócio(s) possuem restrição?*</p>
        <v-radio class="inline" name="possuiNegativacao" v-model="businessData.possuiNegativacao" label="Sim"
          :value="true" v-validate="'required'"></v-radio>
        <v-radio class="inline" name="possuiNegativacao" v-model="businessData.possuiNegativacao" label="Não"
          :value="false"></v-radio>
        <p class="text-xs text-red-600">{{ errors.first("possuiNegativacao") }}</p>
        <div class="boxes_group" style="display: none">
          <v-checkbox class="text-sm" name="contact" :modelValue="contactValue"
            @click.native.prevent="modalContact = true"
            :label="`Aceito receber informações e contatos por ${contactText}.`" />
        </div>

      </div>
      <div class="row mt-2">
        <small>
          *As operações de antecipação de recebíveis e duplicatas oferecem taxas mais vantajosas e seguras para a sua
          empresa.
        </small>
      </div>


      <div class="row mt-4">
        <div class="content_checkboxes">
          <v-checkbox @click="!termos ? modalTermsBusinessInvestor = true : modalTermsBusinessInvestor = false"
            type="checkbox" class="text-sm" name="termos" :error="errors.first('termos')" v-model="termos"
            v-validate="'required:true'">
            <span> Declaro que li e estou de acordo com os
              <span class="text-secondary">Termos e Condições para Tomadores e Investidores</span></span>
          </v-checkbox>
          <v-checkbox @click="!termosPrivacidade ? modalTermsPolitics = true : modalTermsPolitics = false"
            class="text-sm my-2" name="termosPrivacidade" :error="errors.first('termosPrivacidade')"
            v-model="termosPrivacidade" v-validate="'required:true'">
            <span> Declaro que li e estou de acordo com os
              <span class="text-secondary">Termos e Condições de Uso e Política de Privacidade</span></span>
          </v-checkbox>
        </div>
      </div>

      <small v-if="partner" class="w-full flex lg:justify-end">
        Cadastro vinculado ao parceiro: {{ partner.nome }}</small>
      <div class="buttons">
        <v-button class="justify-center lg:justify-start" :loading="loading" @click="submit">Avançar</v-button>
        <v-text-button class="w-full h-10 lg:my-4 flex items-center justify-center lg:justify-end"
          @click="$emit('prevStep')">Voltar</v-text-button>
      </div>
    </div>
    <transition name="fade">
      <modal-basic v-show="modalCc" @closeModal="modalCc = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <h1 class="title">Antecipação de Recebíveis Cartão de Crédito</h1>
          Com a operação de antecipação de recebíveis de cartão de crédito, você pode receber
          os valores das suas vendas a prazo com cartão de crédito de forma antecipada. Isso significa que você não
          precisa esperar pelo prazo de vencimento das parcelas para ter acesso ao dinheiro, o que proporciona maior
          flexibilidade financeira para investir em novos projetos, pagar fornecedores e manter suas finanças em dia.
          <br /><br />
          A
          antecipação de recebíveis de cartão de crédito é uma alternativa segura e confiável para manter o fluxo de caixa
          da sua empresa em dia, com taxas competitivas e condições especiais para clientes do nosso banco.
        </div>
      </modal-basic>
    </transition>

    <transition name="fade">
      <modal-basic v-show="modalCg" @closeModal="modalCg = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <h1 class="title">Capital de Giro</h1>
          O capital de giro é essencial para impulsionar as atividades empresariais. Com ele, você terá mais recursos para
          gerenciar o fluxo de caixa, investir em novos projetos, manter o estoque adequado, fazer reformas e melhorias na
          empresa. Com essa ajuda financeira, é possível ter mais flexibilidade para enfrentar os desafios do mercado e
          alcançar o sucesso nos negócios.
        </div>
      </modal-basic>
    </transition>

    <transition name="fade">
      <modal-basic v-show="modalDup" @closeModal="modalDup = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <h1 class="title">Antecipação de duplicatas</h1>
          Com a antecipação de duplicatas, você pode receber o pagamento de vendas a prazo de forma fácil e rápida,
          mantendo a saúde financeira da sua empresa. Com mais liquidez, você pode investir em novos projetos, pagar
          fornecedores e realizar outras despesas importantes para o seu negócio.
          <br /> <br />
          É importante lembrar que, para solicitar
          a antecipação de duplicatas, é necessário apresentar as notas fiscais que comprovem as vendas a prazo.
        </div>
      </modal-basic>
    </transition>


  </div>
</template>

<script>
import ContactAuthorizationsModal from '@/components/Modal/ContactAuthorizationsModal'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VRadio from '@/components/Inputs/Radio'
import VButton from '@/components/Buttons/Button'
import VTextButton from '@/components/Buttons/TextButton'

import Simulation from '@/services/Landing/Simulation'
import CNPJ from '@/services/Landing/CNPJ'
import ModalBasic from '@/components/Modal/ModalBasic'
import TermsPoliticsModal from '@/components/Modal/TermsPoliticsModal'
import TermsBusinessInvestorModal from '@/components/Modal/TermsBusinessInvestorModal'
export default {
  props: ['businessData', 'reasons', 'partner'],
  components: {
    ContactAuthorizationsModal, VInput, VButton, VTextButton, VCheckbox, VRadio, ModalBasic, TermsPoliticsModal,
    TermsBusinessInvestorModal,
  },
  data() {
    return {
      modalContact: false,
      loading: false,
      loadingName: false,
      partCNPJ: null,
      modalDup: false,
      modalCc: false,
      modalCg: false,
      dup: false,
      cc: false,
      cg: false,
      modalTermsPolitics: false,
      modalTermsBusinessInvestor: false,
      operationTypeSelected: false,
    }
  },
  mounted: async function () {
    if (this.$route.params.slug && !this.partner) {
      try {
        this.loading = true;
        this.partner = (await Simulation.getPartnerData(this.$route.params.slug)).data.data
        console.log('SIMULATION: ', this.partner);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
  computed: {
    contactValue() {
      return Object.values(this.businessData.autorizacoesContato).some(v => v)
    },
    contactText() {
      const text = {
        sms: 'SMS',
        telefone: 'telefone',
        email: 'e-mail',
        whatsApp: 'WhatsApp'
      }
      if (Object.values(this.businessData.autorizacoesContato).every(v => !v)) {
        return Object.values(text).join(', ')
      }
      return Object.entries(this.businessData.autorizacoesContato).map(([key, value]) => value ? text[key] : '').filter(v => v).join(', ')
    }
  },
  methods: {
    showDescDup() {
      this.modalDup = true
    },
    showDescCc() {
      this.modalCc = true
    },
    showDescCg() {
      this.modalCg = true
    },
    async submit() {
      if (!this.loading) {
        this.operationTypeSelected = !this.dup && !this.cc && !this.cg;

        if (await this.$validator.validateAll() && !this.operationTypeSelected) {
          this.loading = true
          const data = { ...this.businessData, antecipacaoDuplicata: this.dup, antecipacaoCartaoCredito: this.cc, capitalGiro: this.cg }
          if (this.microcredit) data.microCredito = this.microcredit
          if (this.partner) data.parceiroId = this.partner.id

          try {
            const grecaptcha = await this.$recaptcha('login')
            const code = (await Simulation.new({ ...data, grecaptcha })).data.data.codigo

            history.pushState({}, null, '/credito-solicitado');

            ga('send', 'event', 'credito-solicitado', 'credito-solicitado', 'credito-solicitado');
            dataLayer.push({ 'event': 'credito-solicitado' });
            ga('set', 'page', 'credito-solicitado');

            setTimeout(() => {
              this.$store.dispatch('notification', { type: 'success', message: 'Pedido realizado com sucesso!' })
              this.$emit('nextStep', code)
            }, 1000);

          } catch (error) {
            if (error.response.data.erros) {
              var str = "";
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => {
                this.errors.add({ field: campo, msg: erro });
                str += "<br />" + erro
              })

              this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' + erro })
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  watch: {
    'businessData.cnpj': async function () {
      if (!this.loadingName && this.businessData.cnpj.length >= 8 && (!this.businessData.nomeEmpresa || this.businessData.cnpj.substring(0, 8) != this.partCNPJ)) {
        this.loadingName = true;
        const res = (await CNPJ.getNameRFB(this.businessData.cnpj)).data.data;

        if (res) {
          if (res.razaoSocial && (!res.nomeFantasia || res.nomeFantasia.indexOf('***') === 0)) {
            this.businessData.nomeEmpresa = res.razaoSocial;
          } else {
            this.businessData.nomeEmpresa = res.nomeFantasia;
          }

          this.partCNPJ = this.businessData.cnpj.substring(0, 8);
        }

        this.loadingName = false;
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.error1
  width: 100%
  font-size: 0.625rem
  --text-opacity: 1
  color: #e53e3e
  color: rgba(229, 62, 62, var(--text-opacity))

.desc
  @apply text-justify
  font-size: 0.775rem
  line-height: 1.5
  margin-bottom: 1rem
  padding-left: 1.5rem
  display: none
.wrapper
  @apply flex justify-center

.main
  @apply flex flex-col items-center
  @screen lg
    @apply flex-row flex-wrap w-full

.title
  @apply w-full text-primary text-xl font-bold text-center mb-4
  @screen lg
    @apply text-2xl text-left

.content
  @apply flex flex-col w-full
  @screen lg
    @apply w-1/2 items-start

.boxes
  @apply w-full flex flex-col justify-center
  @screen lg
    @apply flex-row

.boxes_group
  @apply my-3
  @screen lg
    @apply w-1/2 mx-0

.radio_label
  @apply text-gray-700 mt-2

.radio_1
  @apply mr-1

.radio_2
  @apply ml-4 mr-1

.input_label
  @apply text-gray-700 cursor-pointer

.buttons
  @apply w-full flex flex-col mt-4 items-center
  @screen lg
    @apply items-start flex-row justify-between mt-20

</style>
