<template>
  <div class="wrapper">
    <div class="content_left">
      <transition name="fade">
        <p class="analysis_left">{{loading ? 'Análise em andamento...' : 'Análise concluída!'}}</p>
      </transition>
      <div class="flex flex-col items-center justify-center mr-2 lg:mr-0">
        <div class="circle">
          <div class="outer_circle" :class="loading ? 'spin_infinite' : 'spin_finite'"></div>
          <div class="middle_circle"></div>
          <div class="inner_circle" :class="loading ? wave : 'wave_finish'"></div>
        </div>
        <p v-show="duration.asSeconds() !== 0" class="timer">{{timer}}</p>
      </div>
      <transition name="fade">
        <p class="analysis_right">{{loading ? 'Análise em andamento...' : 'Análise concluída!'}}</p>
      </transition>
    </div>
    <div class="content_right">
      <p class="content_title">Nesse momento, estamos buscando informações nas nossas fontes!</p>
      <div class="contents">
        <transition name="fade">
          <div v-if="business" class="content_one">
            <p class="content_subtitle">Razão Social</p>
            <p class="content_text">{{business.razaoSocial}}</p>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="business" class="content_two">
            <p class="content_subtitle">Nome Fantasia</p>
            <p class="content_text">{{business ? business.nomeFantasia : ''}}</p>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="business" class="content_three">
            <p class="content_subtitle">Data de Fundação</p>
            <p class="content_text">{{business ? business.dataFundacao : ''}}</p>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="business" class="content_four">
            <p class="content_subtitle">Localização</p>
            <p class="content_text">{{business ? business.localizacao : ''}}</p>
          </div>
        </transition>
      </div>
      <div class="buttons">
        <v-button class="justify-center lg:justify-start" :loading="loading" @click="loading ? '' : $emit('nextStep')">Avançar</v-button>
        <!-- <v-text-button class="w-full h-10 lg:my-4 flex items-center justify-center lg:justify-end" :disabled="loading" @click="$emit('prevStep')">Voltar</v-text-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import { setTimeout, setInterval, clearInterval } from 'timers'

export default {
  props: ['loading', 'business', 'success'],
  components: { VButton },
  data () {
    return {
      duration: this.$moment.duration(3, 'minutes'),
      wave: 'wave_start'
    }
  },
  methods: {
    clearTimer () {
      clearInterval(this.interval)
    }
  },
  computed: {
    timer () {
      return this.$moment.utc(this.duration.asMilliseconds()).format('mm:ss')
    }
  },
  watch: {
    success () {
      if (this.success !== null) {
        this.clearTimer()
        this.duration = this.$moment.duration(0, 'seconds')
      }
    }
  },
  async mounted () {
    setTimeout(() => { this.wave = 'wave_middle' }, 3000)

    this.interval = setInterval(() => {
      if (this.duration.asSeconds() === 0) {
        this.clearTimer()
        this.$emit('timeout')
      } else {
        this.duration = this.duration.subtract(1, 'second')
      }
    }, 1000)
  },
  beforeDestroy () {
    this.clearTimer()
  }
}
</script>

<style lang="sass" scoped>

.wrapper
  @apply flex flex-col
  @screen lg
    @apply flex-row

.content_left
  @apply flex flex-row items-center justify-center mb-4
  @screen lg
    @apply w-4/12 flex-col justify-start

.analysis_left
  @apply text-primary text-xl font-bold hidden
  @screen lg
    @apply text-2xl block

.analysis_right
  @apply text-primary text-xl font-bold
  @screen lg
    @apply text-2xl hidden

.circle
  @apply relative w-12 h-12 mb-2
  @screen lg
    @apply w-48 h-48 mt-10 mb-12 mr-0

.timer
  @apply opacity-75 text-xss
  @screen md
    @apply text-sm

.inner_circle
  @apply rounded-full w-12 h-12 absolute bg-repeat-x
  background-image: url('../../assets/images/wave.png')
  background-position: 0px 50%
  @screen lg
    @apply w-48 h-48

.wave_start
  @apply opacity-25
  animation: wave_sm_start 3s linear 1
  @screen lg
    animation: wave_lg_start 3s linear 1

.wave_middle
  @apply opacity-25
  animation: wave_sm_middle 3s linear infinite
  @screen lg
    animation: wave_lg_middle 3s linear infinite

.wave_finish
  animation: wave_sm_finish 2s linear 1
  @screen lg
    animation: wave_lg_finish 2s linear 1

.middle_circle
  @apply rounded-full w-12 h-12 absolute border-6 border-white z-10
  @screen lg
    @apply w-48 h-48 border-12

.outer_circle
  @apply rounded-full border-4 w-12 h-12 absolute z-20
  border-color: rgba(163,140,199,1) rgba(163,140,199,1) rgba(72,27,144,1) rgba(72,27,144,1)
  transform: rotate(-45deg)
  @screen lg
    @apply w-48 h-48 border-10

.spin_infinite
  animation: spin 2500ms ease 0s infinite

.spin_finite
  animation: spin 2500ms ease 0s 1

.content_right
  @apply w-full
  @screen lg
    @apply w-7/12 px-0

.content_title
  @apply text-secondary text-xl font-bold text-center
  @screen lg
    @apply text-2xl text-left

.content_subtitle
  @apply text-secondary font-bold
  @screen lg
    @apply text-xl

.content_text
  @apply uppercase text-sm
  @screen lg
    @apply text-base

.contents
  @apply flex flex-col mt-2 text-center h-56
  @screen lg
    @apply flex-row flex-wrap text-left

.content_one
  @apply my-2 w-full
  @screen lg
    @apply w-1/2 mt-8 mb-6

.content_two
  @apply my-2 w-full
  @screen lg
    @apply w-1/2 pl-4 mt-8 mb-6

.content_three
  @apply my-2 w-full
  @screen lg
    @apply w-1/2 my-6

.content_four
  @apply my-2 w-full
  @screen lg
    @apply w-1/2 pl-4 my-6

.buttons
  @apply w-full flex flex-col mt-8 items-center
  @screen lg
    @apply items-start flex-row justify-between

.btn
  @apply w-48 h-10 rounded-full text-white bg-primary my-4
  @screen lg
    @apply my-0
  &:hover:not([disabled])
    @apply outline-none bg-white border-2 text-primary border-primary
  &:focus
    @apply outline-none
  &[disabled]
    @apply bg-gray-600

.btn_right
  @apply mb-4
  @screen lg
    @apply mt-2 mb-0 mr-0
  &[disabled]
    @apply text-gray-600

@keyframes spin
  from
    transform: rotate(-45deg)
  to
    transform: rotate(315deg)

@keyframes wave_lg_start
  0%
    background-position: 0px -150%
  50%
    background-position: 300px -100%
  100%
    background-position: 600px -50%

@keyframes wave_lg_middle
  0%
    background-position: 0px -50%
  100%
    background-position: 300px -50%

@keyframes wave_lg_finish
  0%
    background-position: 0px -50%
    opacity: 0.25
  50%
    background-position: 300px 0%
    opacity: 0.5
  100%
    background-position: 600px 50%
    opacity: 1

@keyframes wave_sm_start
  0%
    background-position: 0px -10%
  50%
    background-position: 300px 5%
  100%
    background-position: 600px 0%

@keyframes wave_sm_middle
  0%
    background-position: 0px 0%
  100%
    background-position: 300px 0%

@keyframes wave_sm_finish
  0%
    background-position: 0px 5%
    opacity: 0.25
  50%
    background-position: 300px 10%
    opacity: 0.5
  100%
    background-position: 600px 20%
    opacity: 1

</style>
