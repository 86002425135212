<template>
  <div>
    <p class="title">Infelizmente não temos uma oferta para você hoje :(</p>
    <p class="subtitle">Como atuamos com um produto bastante inovador e a todo momento
      estamos aprimorando nosso modelo, para que possamos atender cada vez mais empreendedores como você.</p>
    <p class="text">
      Em breve, pode ser que nossa análise tenha evoluído o bastante para te conceder esse capital para {{reason ? reason.descricao : ''}}.
      Sabemos que muitas vezes o crédito é emergencial, por isso, preferimos te
      informar o mais breve para poder buscar outras alternativas.
      Sugerimos que caso ainda haja interesse daqui <span class="font-bold">60 dias</span> faça uma nova solicitação.
      Obrigado pela compreensão e sucesso no seu negócio!</p>

  </div>
</template>

<script>
import VTextButton from '@/components/Buttons/TextButton'

export default {
  props: ['reason'],
  components: { VTextButton },
  data () {
    return {
    }
  },
  methods: {
    mounted() {
     history.pushState({}, null, '/credito-reprovado');

      ga('send', 'event', 'credito-reprovado', 'credito-reprovado', 'credito-reprovado');
      dataLayer.push({'event': 'credito-reprovado'});
      ga('set', 'page', 'credito-reprovado');
      }
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply mb-4 text-primary text-xl font-bold text-center
  @screen lg
    @apply text-3xl text-left

.subtitle
  @apply mb-4 text-gray-700 text-justify
  @screen lg
    @apply text-2xl

.text
  @apply text-gray-700 leading-relaxed text-justify

.content
  @apply flex mt-6 mb-4 flex-col items-center
  @screen lg
    @apply flex-row items-start

.content_section
  @apply flex flex-col justify-between
  @screen lg
    @apply w-1/3 h-80

.card
  @apply h-80 flex flex-col border-2 items-center justify-around mb-4 w-72
  @screen lg
    @apply w-1/3 ml-8 mb-0

.card_info
  @apply h-16 w-full bg-gray-300 items-center justify-center flex flex-col

.card_content
  @apply flex flex-col text-gray-700 mx-6

.card_footer
  @apply text-secondary text-center font-bold

.logo-creditas
  @apply w-48 h-10 bg-no-repeat
  background-image: url('../../assets/images/partners/logo-creditas.svg')

.logo-size
  @apply w-32 h-12 bg-no-repeat mt-2
  background-image: url('../../assets/images/partners/logo-size.png')

</style>
