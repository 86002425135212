<template>
  <div>
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false" />
    </transition>
    <div class="wrapperr" :class="modal ? 'blur' : ''">
      <div class="main">
        <div class="container">
          <nav-bar @login="modal = true" />
        </div>
        <div class="slider_back">
          <div class="slider" :class="`w-${step*3}/12`"></div>
        </div>
        <div class="container content">
          <transition name="fade">
            <step1 v-if="step === 1" :businessData="businessData" @nextStep="step = 2" :microcredit="microcredit"
              :loading="loading">
            </step1>
            <step2 v-else-if="step === 2" :businessData="businessData" :reasons="reasons" :partner="partner"
              :microcredit="microcredit" @nextStep="doAnalysis" @prevStep="step = 1"></step2>
            <step3 v-else-if="step === 3" @nextStep="step = 4" :loading="loading" :business="business"
              :success="success" @timeout="setTimeout"></step3>
            <step4-success v-else-if="step === 4 && !timeout && success" :password="password" :preApproved="preApproved"
              :giveUpForm="giveUpForm" :userId="$route.params.userId" @giveUp="giveUp" @resetGiveUp="resetGiveUp">
            </step4-success>
            <step4-fail v-else-if="step === 4 && !timeout && !success" @prevStep="$router.push('/')"
              :reason="reasons.find(v => String(v.id) === String(businessData.motivoSolicitacaoId))"></step4-fail>
            <step4-timeout v-else-if="step === 4 && timeout" @prevStep="$router.push('/')"></step4-timeout>
          </transition>
        </div>
        <div class="footer">
          <p class="footer_text container">
            Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital Tecnologia Ltda – CNPJ
            28.304.222/0001-17, Avenida Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
            Esse site está em fase pré-operacional e sendo aprimorado pela equipe da TuTu Digital. Até que esse
            aprimoramento seja concluído, todas as operações serão realizadas
            a partir do contato individualizada da Tutu Digital com cada cliente.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import Step1 from '@/components/Simulation/Step1'
import Step2 from '@/components/Simulation/Step2'
import Step3 from '@/components/Simulation/Step3'
import Step4Success from '@/components/Simulation/Step4Success'
import Step4Fail from '@/components/Simulation/Step4Fail'
import Step4Timeout from '@/components/Simulation/Step4Timeout'

import Simulation from '@/services/Landing/Simulation'
import { setInterval, clearInterval } from 'timers'

export default {
  name: 'simulation',
  props: ['microcredit'],
  components: {
    NavBar,
    ChooseLoginModal,
    'step1': Step1,
    'step2': Step2,
    'step3': Step3,
    'step4-success': Step4Success,
    'step4-fail': Step4Fail,
    'step4-timeout': Step4Timeout
  },
  data() {
    return {
      modal: false,
      step: 1,
      loading: false,
      timeout: false,

      partner: null,
      success: null,
      business: null,
      preApproved: null,
      reasons: [],

      businessData: {
        valorEmprestimoDesejado: 10000,
        prazoDesejado: 12,
        nomeEmpresa: '',
        emailContato: '',
        cnpj: '',
        telefoneContatoDireto: '',
        faturamentoMedioMensal: '',
        possuiNegativacao: null,
        motivoSolicitacaoId: -1,
        autorizacoesContato: {
          email: true,
          sms: true,
          telefone: true,
          whatsApp: true
        }
      },

      password: {
        codigoProposta: null,
        senha: '',
        confirmacaoSenha: ''
      },

      giveUpForm: {
        pedidoEmprestimoId: null,
        motivoDesistenciaId: null,
        aceitoReceberNovasPropostasNoFuturo: true,
        observacoes: ''
      }
    }
  },
  methods: {
    doAnalysis(code) {
      this.loading = true
      this.step = 3
      this.interval = setInterval(() => this.checkStatus(code), 1000)
    },
    async checkStatus(code) {
      const response = (await Simulation.status(code)).data.data
      if (response.dadosCNPJ) {
        this.business = response.dadosCNPJ
      }
      if (response.analisado) {
        clearInterval(this.interval)
        this.success = response.aprovado
        if (this.success) {
          this.preApproved = (await Simulation.preApproved(code)).data.data
          this.password.codigoProposta = code
          this.giveUpForm.pedidoEmprestimoId = code

          /* if (history.pushState) {
             history.pushState({}, null, '/credito-aprovado');
 
       ga('send', 'event', 'credito-aprovado', 'event', 'credito-aprovado');
       ga('send', 'event', 'credito-aprovado', 'credito-aprovado', 'credito-aprovado');
       dataLayer.push({'event': 'credito-aprovado'});
       ga('set', 'page', 'credito-aprovado');
           }
         } else {
           if (history.pushState) {
                   ga('send', 'event', 'credito-negado', 'event', 'credito-negado') ;
       ga('send', 'event', 'credito-negado', 'credito-negado', 'credito-negado');
       dataLayer.push({'event': 'credito-negado'});
       ga('set', 'page', 'credito-negado');
             history.pushState({}, null, '/credito-negado');
           }*/
        }
        this.loading = false
      }
    },
    async giveUp() {
      try {
        await Simulation.giveUp(this.giveUpForm)
        this.$store.dispatch('notification', { type: 'success', message: 'Desistência do empréstimo realizada com sucesso :/' })
        this.$router.push('/')
      } catch (error) {
        this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro ao desistir do empréstimo. Revise o formulário.' })
      }
    },
    resetGiveUp() {
      this.giveUpForm.motivoDesistenciaId = -1
      this.giveUpForm.aceitoReceberNovasPropostasNoFuturo = true
      this.giveUpForm.observacoes = ''
    },
    setTimeout() {
      this.timeout = true
      this.loading = false
      clearInterval(this.interval)
    }
  },
  async created() {
    if (this.$route.params.userId && this.$route.params.solicitationId) {
      const response = (await Simulation.preApproved(
        this.$route.params.solicitationId
      )).data.data
      this.preApproved = response
      this.giveUpForm.pedidoEmprestimoId = this.$route.params.solicitationId
      this.step = 4
      this.success = true
    }
  },
  async mounted() {
    try {
      this.reasons = (await Simulation.getReasons()).data.data
    } catch (error) {
      console.error(error)
    }
    if (this.$route.params.slug) {
      try {
        this.loading = true;
        this.partner = (await Simulation.getPartnerData(this.$route.params.slug)).data.data
        console.log('SIMULATION: ', this.partner);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="sass" scoped>

.wrapperr
  @apply w-screen h-full flex flex-col
  @screen lg
    @apply h-screen

.main
  @apply flex flex-col h-full

.slider_back
  @apply h-1 bg-gray-400 my-5

.slider
  @apply h-full bg-primary
  transition: all 500ms

.content
  @apply mb-auto
  @screen lg
    @apply mt-8

.footer
  @apply w-full bg-gray-200 flex justify-center py-2

.footer_text
  @apply text-center text-sm text-gray-700 leading-relaxed

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

</style>
