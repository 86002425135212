/* eslint-disable */
import Vue from 'vue'

const MathTutu = {
  calcularAliquotaIRDias: (dias) => {
    if (dias <= 180) return 22.5
    if (dias >= 181 && dias <= 360) return 20
    if (dias >= 361 && dias <= 720) return 17.5
    if (dias > 720) return 15

    return 22.5
  },

  calcularAliquotaIR: function (dataOperacao, dataPagamento) { return this.calcularAliquotaIRDias(Vue.moment.duration(dataPagamento.diff(dataOperacao)).asDays()) },

  calcularValorIR: (aliquota, juros) => juros * (aliquota / 100),

  calcularValorIRRound: function (aliquota, juros) { return this.roundUp(this.calcularValorIR(aliquota, juros), 2) },

  calcularRetorno: function (valor, jurosAoMes, parcelas) {
    const carencia = 0
    const rate = this.roundUp((jurosAoMes / 100), 2)
    var x = pmt(parseFloat(valor), rate, parseInt(parcelas), parseInt(carencia))
    return x.pmt * parcelas
  },

  jurosAoAnoParaMes: (valor) => (Math.pow(1 + valor / 100, 1 / 12) - 1) * 100,

  roundUp: (value, digits) => {
    let pow = Math.pow(10, digits)
    return Math.trunc(value * pow + Math.sign(value) * 0.5) / pow
  },

  fullPmt: function (pv, rate, nper, diascarencia) {
    let result = {}

    result.rateDiaria = (Math.pow(1 + (rate / 100), 1 / 30) - 1)
    result.carencia = (Math.pow((result.rateDiaria) + 1, diascarencia) - 1)
    result.valorFuturo = (pv * (1 + result.carencia))
    result.pmt = (result.valorFuturo * ((Math.pow((1 + (rate / 100)), nper)) * (rate / 100)) / ((Math.pow((1 + (rate / 100)), nper)) - 1))
    result.pmtRound = this.roundUp(result.pmt, 2)
    result.JurosSomadoAoSaldoInicial = result.pmtRound * nper
    result.TotalJuros = result.JurosSomadoAoSaldoInicial - pv

    result.rateDiariaRound = this.roundUp(result.rateDiaria, 9)
    result.carenciaRound = this.roundUp(result.carencia, 9)
    result.valorFuturoRound = this.roundUp(result.valorFuturo, 5)

    return result
  },

  pmt: function (pv, rate, nper, diascarencia) { return this.fullPmt(pv, rate, nper, diascarencia).pmt },

  pmtRound: function (pv, rate, nper, diascarencia) { return this.fullPmt(pv, rate, nper, diascarencia).pmtRound },

  price: function (vf, nper, rate, diasCarencia) {
    let price = {
      parcelas: [],
      jurosDevidos: 0,
      principalDevido: 0,
      totalDevido: 0,
      totalRetorno: 0,
      totalJuros: 0,
      totalIr: 0
    }

    const pmt = this.pmt(vf, rate, nper, diasCarencia)

    const self = this
    const criarParcela = function (numero, saldoDevedor, rate, pmt) {
      var parcela = {
        saldoInicial: saldoDevedor,
        juros: saldoDevedor * rate,
        prestacao: pmt,
        numero: numero
      }

      parcela.saldoAtualizado = saldoDevedor + parcela.juros
      parcela.amortizacao = parcela.prestacao - parcela.juros
      parcela.saldoDevedor = saldoDevedor - parcela.amortizacao

      parcela.prestacaoRound = self.roundUp(parcela.prestacao, 2)
      parcela.jurosRound = self.roundUp(parcela.juros, 2)
      parcela.saldoDevedorRound = self.roundUp(parcela.saldoDevedor, 2)
      parcela.amortizacaoRound = self.roundUp(parcela.amortizacao, 2)
      parcela.saldoAtualizadoRound = self.roundUp(parcela.saldoAtualizado, 2)
      parcela.saldoInicialRound = self.roundUp(parcela.saldoInicial, 2)

      let aliquota = self.calcularAliquotaIR(Vue.moment().add(parcela.numero * -1, 'months'), Vue.moment())

      parcela.ir = self.calcularValorIRRound(aliquota, parcela.jurosRound)

      return parcela
    }

    const getUltimaParcela = function () {
      return price.parcelas[price.parcelas.length - 1]
    }

    const getSaldoDevedorUltimaParcela = function () {
      return getUltimaParcela().saldoDevedor
    }

    const addParcela = function (numero, rate, pmt) {
      let saldo = price.parcelas.length == 0 ? price.principalDevido
        : getSaldoDevedorUltimaParcela()

      price.parcelas.push(criarParcela(numero,
        saldo,
        rate,
        pmt))
    }

    price.principalDevido = vf

    for (let i = 1; i <= nper; i++) {
      addParcela(i, rate / 100, pmt)

      price.totalJuros += price.parcelas[i - 1].juros
      price.totalIr += price.parcelas[i - 1].ir
    }

    price.totalRetorno = getUltimaParcela().prestacao * nper

    return price
  }
}

export default MathTutu
